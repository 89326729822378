import styled from '@emotion/styled'
import tw from 'twin.macro'

export const LogosWrapper = styled.div`
  ${tw`
    max-w-7xl
    mx-auto
    pt-16
  `}
  &.enterprise-connections {
    ${tw` 
      lg:-mt-20
    `}
  }
`

export const LogosGridTop = styled.div`
  ${tw`
    mt-6
    grid
    grid-cols-3
    md:grid-cols-3
    lg:gap-0
    lg:grid-cols-5
  `}
`

export const LogosGridBottom = styled.div`
  ${tw`
    my-6
    lg:-mt-3
    lg:mb-6
    lg:mt-0
    grid
    grid-cols-3
    md:grid-cols-4
    lg:gap-24
    lg:grid-cols-6
    gap-y-6
  `}
`

export const CircleWrapper = styled.div`
  ${tw`
    flex
    justify-center
    col-span-1
  `}
  &.logo-1 {
    ${tw`
      lg:col-start-2
    `}
  }
  &.logo-2 {
    ${tw`
      lg:col-start-3
    `}
  }
  &.logo-3 {
    ${tw`
      lg:col-start-4
    `}
  }
  &.logo-4 {
    ${tw`
      lg:col-start-2
    `}
  }
  &.logo-5 {
    ${tw`
      lg:col-start-3
    `}
  }
  &.logo-6 {
    ${tw`
      lg:col-start-4
    `}
  }
  &.logo-7 {
    ${tw`
      hidden
      sm:flex
      lg:col-start-5
    `}
  }
`

export const Circle = styled.div`
  ${tw`
    rounded-full
    bg-white
    h-20
    w-20
    flex
    items-center
    justify-center
    shadow-lg
  `}
  svg {
    ${tw`
      h-12
      w-12
    `}
  }
`
