import * as React from 'react'

function SvgAppSettingsIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={20}
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.919 14.891H2.002v-10H9.92v.833h1.583V2.391c0-.917-.712-1.667-1.583-1.667L2.002.733C1.132.733.42 1.474.42 2.39v15c0 .917.712 1.667 1.583 1.667H9.92c.87 0 1.583-.75 1.583-1.667v-3.333H9.92v.833zm3.167-5c0-.108-.016-.217-.032-.325l.507-.4a.44.44 0 00.103-.55l-.452-.8a.396.396 0 00-.215-.18.374.374 0 00-.276.013l-.57.25a2.587 2.587 0 00-.514-.325l-.08-.641a.428.428 0 00-.129-.26.389.389 0 00-.258-.107l-.887-.017a.396.396 0 00-.396.367l-.079.658c-.19.084-.356.192-.514.325l-.57-.25a.38.38 0 00-.491.167l-.452.8a.433.433 0 00.103.55l.507.4a.936.936 0 00-.055.325c0 .108.015.208.031.308l-.506.409a.429.429 0 00-.103.541l.443.809a.385.385 0 00.499.175l.578-.259c.158.134.332.25.53.334l.08.641c.023.209.19.367.395.367h.887c.198 0 .364-.158.396-.367l.079-.641c.19-.084.364-.2.53-.334l.578.259a.387.387 0 00.499-.175l.443-.809a.429.429 0 00-.103-.541l-.506-.409c-.016-.1 0-.2 0-.308zm-2.375 1.25c-.658 0-1.188-.558-1.188-1.25s.53-1.25 1.188-1.25c.657 0 1.187.558 1.187 1.25s-.53 1.25-1.187 1.25z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgAppSettingsIcon
