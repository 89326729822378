import styled from '@emotion/styled'
import tw from 'twin.macro'

export const Grid = styled.div`
  ${tw`
    pt-20
    md:pt-32
    grid
    grid-cols-1
    lg:grid-cols-4
  `}
`

export const Title = styled.h2`
  ${tw`
    text-2xl
    font-medium
    tracking-tight
    sm:text-3xl
  `}
  color: #5851FF;
`

export const Description = styled.p`
  ${tw`
    mt-6
    max-w-3xl
    text-base
    leading-7
    text-gray-700
  `}
`

export const Anchor = styled.div`
  ${tw`
    mt-6
  `}
  a {
    ${tw`
      text-base
      font-medium
    `}
    color: #5851FF;
  }
`

export const Illustration = styled.div`
  ${tw`
    col-span-2
    row-start-1
  `}
`

export const AnchorBlock = styled.div`
  ${tw`
    col-span-1
    pt-12
    text-left
  `}
  &.anchor-1 {
    ${tw`
      row-start-2
      lg:col-start-1
      lg:row-start-1
    `}
  }
  &.anchor-2 {
    ${tw`
      row-start-3
      lg:col-start-4
      lg:row-start-1
    `}
  }
`
