import React from 'react'
import { HeroIllustration } from '../images/enterprise_connections/hero'
import { Anchor, Description, Grid, Title, Illustration, AnchorBlock } from './style'

const SectionAnchors = ({ anchors, anchors_id }: any) => {
  return (
    <Grid>
      <AnchorBlock className={'anchor-1'}>
        <Title>{anchors[0].title}</Title>
        <Description>{anchors[0].description}</Description>
        <Anchor>
          <a href={`#${anchors_id[0]}`}>{anchors[0].anchor} &rarr;</a>
        </Anchor>
      </AnchorBlock>
      <Illustration>
        <HeroIllustration />
      </Illustration>
      <AnchorBlock className={'anchor-2'}>
        <Title>{anchors[1].title}</Title>
        <Description>{anchors[1].description}</Description>
        <Anchor>
          <a href={`#${anchors_id[1]}`}>{anchors[1].anchor} &rarr;</a>
        </Anchor>
      </AnchorBlock>
    </Grid>
  )
}

export default SectionAnchors
