import React from 'react'
import tw from 'twin.macro'
import { ImageStyle } from './style'

const LargeImage = ({
  shadow,
  image,
  imageType,
}: {
  shadow: boolean
  image: any
  imageType: string
}) => (
  <ImageStyle css={[shadow ? tw`shadow-2xl` : tw`shadow-none`]}>
    <div className={imageType}>{image}</div>
  </ImageStyle>
)

export default LargeImage
