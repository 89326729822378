import { RichText, RichTextBlock } from 'prismic-reactjs'
import React from 'react'
import Feature from '../content_sections/feature'
import { ImageWrapper, Grid, FeatureWrapper, FeaturesRow } from './style'

type SectionH3Image = {
  image: any
  contentPosition?: string
  features: any
  slug: string
  featuresOrder: string
  imageOrder: string
  icons: any
}

const SectionH3Image = ({
  image,
  contentPosition,
  features,
  slug,
  featuresOrder,
  imageOrder,
  icons,
}: SectionH3Image) => {
  return (
    <Grid className={`content-${contentPosition} content-${featuresOrder}`}>
      <FeatureWrapper className={`feature-${contentPosition} feature-${featuresOrder}`}>
        <FeaturesRow>
          {features.map(
            (
              feature: { title: string; description: { raw: RichTextBlock[] } },
              index: React.Key,
            ) => {
              return (
                <Feature
                  key={index}
                  icon={icons !== null && icons[index]}
                  className="fourth-fifths"
                  title={feature.title}
                  description={RichText.render(feature.description.raw)}
                  slug={slug}
                />
              )
            },
          )}
        </FeaturesRow>
      </FeatureWrapper>
      <ImageWrapper className={`image-${contentPosition} image-${imageOrder}`}>
        {image}
      </ImageWrapper>
    </Grid>
  )
}

export default SectionH3Image
