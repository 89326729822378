import styled from '@emotion/styled'
import tw from 'twin.macro'

export const Grid = styled.div`
  ${tw`
    grid
    grid-cols-1
    justify-self-end
  `}
  :not(.content-to-top, .ontent-to-bottom) {
    ${tw`
      my-16
    `}
  }
  &.content-to-top {
    ${tw`
      mt-16
      mb-0
    `}
  }
  &.content-to-bottom {
    ${tw`
      mt-0
      mb-16
    `}
  }
  @media (min-width: 1024px) {
    &.content-to-left {
      grid-template-columns: 1fr 3fr;
    }
    &.content-to-right {
      grid-template-columns: 3fr 1fr;
    }
  }
`

export const ImageWrapper = styled.div`
  :not(.image-to-top, .image-to-bottom) {
    order: 2;
  }
  &.image-to-top {
    order: 2;
  }
  &.image-to-bottom {
    order: 1;
    @media (min-width: 1024px) {
      order: 2;
    }
  }
  .authentication {
    ${tw`
      flex
      justify-end
    `}
  }
  .enterprise-connections {
    ${tw`
      rounded-lg
      shadow-lg
      mb-8
      flex
      justify-start
    `}
  }
  div.image-to-top {
    ${tw`
      overflow-hidden
      rounded-t-lg
      order-2
      mt-8
      lg:mt-0
    `}
  }
  div.image-to-bottom {
    ${tw`
      overflow-hidden
      rounded-b-lg
      mb-8
      lg:mb-0
    `}
  }
  &.image-to-right {
    ${tw`
      mr-0
    `}
  }
`

export const FeatureWrapper = styled.div`
  ${tw`
    flex
    ml-0
    lg:ml-8
  `}
  &.feature-to-top {
    align-items: flex-start;
    order: 1;
  }
  &.feature-to-bottom {
    align-items: flex-end;
    order: 2;
    @media (min-width: 1024px) {
      order: 1;
    }
  }
  &.feature-to-left {
    ${tw`
      order-first
    `}
  }
  &.feature-to-right {
    ${tw`
      order-last
    `}
  }
`

export const FeaturesRow = styled.div`
  ${tw`
    flex
    flex-col
    w-full
  `}
`
