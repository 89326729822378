import * as React from 'react'

function SvgAuth0Logo(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 90 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.13.698h-8.526l2.635 8.213h8.527l-6.899 4.898 2.636 8.259c4.441-3.235 5.892-8.131 4.264-13.157L21.131.698zM1.44 8.911h8.528L12.604.698H4.077L1.44 8.911c-1.63 5.026-.178 9.922 4.263 13.157l2.636-8.26-6.9-4.897zm4.264 13.157l6.9 4.888 6.899-4.888-6.9-4.969-6.899 4.969zM70.06 8.71c-2.019 0-3.301.916-3.909 2.402h-.153V4.35H62.97v17.676h3.096v-7.669c0-1.898 1.18-2.993 2.839-2.993 1.624 0 2.583 1.035 2.583 2.804v7.858h3.095v-8.374c0-3.172-1.796-4.942-4.523-4.942zM82.95 4.32c-4.189 0-6.71 3.278-6.72 8.975 0 5.713 2.513 9.035 6.72 9.035 4.209 0 6.721-3.312 6.721-9.035-.002-5.688-2.53-8.975-6.72-8.975zM79.426 13.3c.009-4.155 1.363-6.344 3.526-6.344 1.477 0 2.576 1.015 3.13 2.973l-6.599 4.796a16.188 16.188 0 01-.056-1.425zm3.526 6.37c-1.528 0-2.654-1.08-3.185-3.178l6.636-4.824c.055.541.083 1.085.083 1.63 0 4.189-1.355 6.369-3.534 6.369v.003zM49.04 16.507c0 2-1.428 2.993-2.796 2.993-1.488 0-2.48-1.053-2.48-2.72V8.896h-3.096v8.363c0 3.156 1.796 4.943 4.379 4.943 1.966 0 3.351-1.035 3.95-2.506h.138v2.335h3V8.895H49.04v7.612zM33.67 8.697c-2.761 0-4.881 1.23-5.565 3.625l2.89.41c.305-.897 1.18-1.667 2.692-1.667 1.437 0 2.223.735 2.223 2.027v.055c0 .889-.931.931-3.248 1.18-2.544.27-4.988 1.03-4.988 3.987 0 2.582 1.89 3.95 4.394 3.95 2.062 0 3.292-.966 3.856-2.069h.103V22h2.975v-8.788c.003-3.471-2.827-4.514-5.332-4.514zm2.252 8.592c0 1.462-1.18 2.702-3.044 2.702-1.291 0-2.214-.59-2.214-1.728 0-1.188 1.035-1.684 2.42-1.88.812-.111 2.436-.317 2.838-.641v1.547zm22.82-11.675h-3.095v3.289h-1.879v2.459h1.879v7.27c-.017 2.472 1.779 3.686 4.105 3.618a6.361 6.361 0 001.574-.229V19.57c-.235.031-.783.102-1.182.11-.777.018-1.402-.273-1.402-1.521v-6.797h2.591v-2.46h-2.59V5.615z"
        fill="#000"
      />
    </svg>
  )
}

export default SvgAuth0Logo
