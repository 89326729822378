import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export const QuickOnboardingForSSO = () => {
  return (
    <StaticImage
      src="https://res.cloudinary.com/cryptr/image/upload/v1655981490/Cryptr%20www/Landing%20pages/Enterprise%20ready/Optimized%20images/for_the_it_manager_of_your_customer_illustration_nrxmym.jpg"
      alt="Quick onboarding for SSO"
      imgStyle={{ borderRadius: '0.5rem' }}
      quality={90}
      formats={['auto', 'webp', 'avif']}
      placeholder="none"
      className="enterprise-connections"
    />
  )
}
