import React from 'react'
import { LogosGridTop, LogosWrapper, CircleWrapper, Circle, LogosGridBottom } from './style'

const SectionLogos = ({ logos, slug }: any) => (
  <LogosWrapper className={slug}>
    <LogosGridTop>
      {logos.slice(0, 3).map((logo: any, index: number) => {
        return (
          <CircleWrapper key={index} className={`logo-${index + 1}`}>
            <Circle>{logo}</Circle>
          </CircleWrapper>
        )
      })}
    </LogosGridTop>
    <LogosGridBottom>
      {logos.slice(3, 7).map((logo: any, index: number) => {
        return (
          <CircleWrapper key={index} className={`logo-${index + 4}`}>
            <Circle>{logo}</Circle>
          </CircleWrapper>
        )
      })}
    </LogosGridBottom>
  </LogosWrapper>
)

export default SectionLogos
