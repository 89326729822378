import styled from '@emotion/styled'
import tw from 'twin.macro'

export const ImageStyle = styled.div`
  ${tw`
    rounded-lg
    mt-8
    mb-16
    lg:mb-0
    overflow-hidden
    flex
    justify-center
  `}
  img {
    ${tw`
      w-full
      lg:w-auto
    `}
  }
  div.desktop-image {
    ${tw`
      block
    `}
  }
  div.mobile-image {
    ${tw`
      hidden
    `}
  }
  @media all and (max-width: 499px) {
    div.desktop-image {
      ${tw`
        hidden
      `}
    }
    div.mobile-image {
      ${tw`
        block
      `}
    }
  }
`
