import * as React from 'react'

function SvgHandsHelpingIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={13}
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M12.2 4.875H8.4v1.422c0 1.008-.808 1.828-1.8 1.828-.993 0-1.8-.82-1.8-1.828V3.209l-1.623.99A1.627 1.627 0 002.4 5.592v1.2l-2 1.174a.817.817 0 00-.293 1.11l2 3.519c.22.388.71.52 1.093.297l2.585-1.516H9.2c.882 0 1.6-.729 1.6-1.625h.4c.442 0 .8-.363.8-.813V7.312h.2c.332 0 .6-.271.6-.609V5.484a.603.603 0 00-.6-.61zm3.692-.95l-2-3.519A.794.794 0 0012.8.11l-2.585 1.516H7.66c-.3 0-.593.086-.848.246l-.837.53a.81.81 0 00-.375.689v3.207c0 .561.447 1.015 1 1.015.552 0 1-.454 1-1.015V4.062h4.6c.772 0 1.4.638 1.4 1.422v.724l2-1.173a.82.82 0 00.292-1.11z"
          fill={props.color}
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h16v13H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgHandsHelpingIcon
