import * as React from 'react'

function SvgAdfsLogo(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 61 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M8.532.153H.125V8.56h8.407V.153z" fill="#F25022" />
      <path d="M17.824.153H9.417V8.56h8.407V.153z" fill="#7FBA00" />
      <path d="M8.532 9.445H.125v8.407h8.407V9.445z" fill="#00A4EF" />
      <path d="M17.824 9.445H9.417v8.407h8.407V9.445z" fill="#FFB900" />
      <path
        d="M30.952 11.45h-4.099l-.92 2.552h-1.33l3.735-9.783h1.129l3.742 9.783h-1.323l-.934-2.553zm-3.71-1.063h3.327l-1.666-4.575-1.66 4.575zm7.973 3.615V4.22h2.761c.851 0 1.604.188 2.258.565a3.796 3.796 0 011.511 1.606c.359.694.54 1.491.545 2.392v.624c0 .923-.18 1.732-.538 2.426a3.732 3.732 0 01-1.525 1.6c-.658.371-1.427.561-2.305.57h-2.707zm1.29-8.721v7.666h1.357c.994 0 1.767-.309 2.318-.927.555-.618.833-1.498.833-2.64v-.572c0-1.11-.262-1.973-.786-2.586-.52-.619-1.259-.932-2.217-.941h-1.505zm13.824 4.4h-4.106v4.321h-1.29V4.22h6.06v1.062h-4.77v3.346h4.106v1.055zm5.78-.04c-1.106-.317-1.912-.707-2.418-1.169a2.255 2.255 0 01-.753-1.72c0-.77.307-1.406.92-1.908.619-.506 1.42-.76 2.406-.76.672 0 1.27.13 1.794.39.529.26.937.619 1.223 1.076.291.457.437.956.437 1.498h-1.297c0-.591-.188-1.055-.564-1.39-.377-.341-.907-.512-1.593-.512-.636 0-1.133.142-1.491.424-.354.278-.531.665-.531 1.162 0 .399.168.737.504 1.015.34.273.916.524 1.727.752.815.229 1.45.482 1.908.76.461.273.802.593 1.021.96.224.368.336.8.336 1.297 0 .793-.309 1.43-.927 1.909-.618.474-1.445.712-2.48.712a4.632 4.632 0 01-1.88-.383c-.583-.26-1.033-.614-1.351-1.062a2.595 2.595 0 01-.47-1.525h1.296c0 .591.217 1.06.652 1.404.439.34 1.023.51 1.753.51.681 0 1.203-.138 1.566-.416.363-.277.544-.656.544-1.135 0-.48-.168-.85-.504-1.109-.336-.264-.945-.524-1.827-.78z"
        fill="#757575"
      />
    </svg>
  )
}

export default SvgAdfsLogo
