import { RichText, RichTextBlock } from 'prismic-reactjs'
import React, { ReactElement } from 'react'
import CallToAction from '../../elements/call_to_action'
import Container from '../../elements/container'
import { HeroCallToActionsWrapper, HeroIntroTitle, HeroWrapper, ProductCategory } from '../style'
import { CenterHeroStyle, HeroDescription } from './style'

type CenterHeroProps = {
  title: RichTextBlock[] | any
  description?: RichTextBlock[] | any
  textAlignment: 'left' | 'center'
  pageID?: string
  category?: string
  icon?: ReactElement
  call_to_action?: any
  call_to_action_color?: string
  children?: React.ReactNode
  background_shape?: any
}

const CenterHero = ({
  title,
  description,
  textAlignment,
  category,
  pageID,
  icon,
  call_to_action,
  call_to_action_color,
  children,
  background_shape,
}: CenterHeroProps) => (
  <HeroWrapper>
    {background_shape}
    <Container>
      {category && (
        <ProductCategory>
          <header>
            {icon}
            <h1 className={pageID}>{category}</h1>
          </header>
        </ProductCategory>
      )}
      <CenterHeroStyle className={textAlignment}>
        <HeroIntroTitle>{RichText.render(title)}</HeroIntroTitle>
        {description && (
          <HeroDescription className={pageID}>{RichText.render(description)}</HeroDescription>
        )}
        <HeroCallToActionsWrapper>
          {call_to_action !== undefined && (
            <>
              <CallToAction>
                <a
                  href={call_to_action[0].call_to_action_path}
                  className={`color ${call_to_action_color}`}
                >
                  {call_to_action[0].call_to_action_text}
                  {category !== 'Enterprise Connections' && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
                      />
                    </svg>
                  )}
                </a>
              </CallToAction>
              {call_to_action.length > 1 && (
                <CallToAction>
                  <a
                    href={call_to_action[1].call_to_action_path}
                    className={`white ${call_to_action_color}`}
                  >
                    {call_to_action[1].call_to_action_text}
                  </a>
                </CallToAction>
              )}
            </>
          )}
        </HeroCallToActionsWrapper>
        {children}
      </CenterHeroStyle>
    </Container>
  </HeroWrapper>
)

export default CenterHero
