import * as React from 'react'

function SvgProgressBarIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={9}
      viewBox="0 0 20 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.459.998v7.126h19.004V.998H.459zm17.816 5.938H1.647v-4.75h16.628v4.75z"
        fill={props.color}
      />
      <path d="M2.834 3.373h8.314V5.75H2.834V3.373z" fill={props.color} />
    </svg>
  )
}

export default SvgProgressBarIcon
