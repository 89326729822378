import * as React from 'react'

function SvgOktaLogo(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 81 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.882 7.699c-5.56 0-10.06 4.5-10.06 10.06 0 5.56 4.5 10.06 10.06 10.06 5.56 0 10.06-4.5 10.06-10.06 0-5.56-4.5-10.06-10.06-10.06zm0 15.1c-2.78 0-5.04-2.26-5.04-5.04 0-2.78 2.26-5.04 5.04-5.04 2.78 0 5.04 2.26 5.04 5.04 0 2.78-2.26 5.04-5.04 5.04zM28.563 21.139c0-.8.96-1.18 1.52-.62 2.52 2.56 6.68 6.96 6.7 6.98.06.06.12.16.36.24.1.04.26.04.44.04h4.54c.82 0 1.06-.94.68-1.42l-7.52-7.7-.4-.4c-.86-1.02-.76-1.42.22-2.46l5.96-6.66c.38-.48.14-1.4-.7-1.4h-4.12c-.16 0-.28 0-.4.04-.24.08-.34.16-.4.24-.02.02-3.32 3.58-5.36 5.76-.56.6-1.56.2-1.56-.62V1.739c0-.58-.48-.8-.86-.8h-3.36c-.58 0-.86.38-.86.72v25.32c0 .58.48.74.88.74h3.36c.52 0 .86-.38.86-.76V21.139h.02zM56.001 26.859l-.36-3.36c-.04-.46-.48-.78-.94-.7-.26.04-.52.06-.78.06-2.68 0-4.86-2.1-5.02-4.76v-4.4c0-.54.4-.98.94-.98h4.5c.32 0 .8-.28.8-.86v-3.18c0-.62-.4-.94-.76-.94h-4.54c-.52 0-.94-.38-.96-.9v-5.1c0-.32-.24-.8-.86-.8h-3.34c-.42 0-.82.26-.82.78v16.38c.14 5.44 4.6 9.78 10.06 9.78.46 0 .9-.04 1.34-.1.48-.06.8-.46.74-.92zM80.241 22.639c-2.84 0-3.28-1.02-3.28-4.84V8.579c0-.32-.24-.86-.88-.86h-3.36c-.42 0-.88.34-.88.86v.42a9.932 9.932 0 00-4.96-1.32c-5.56 0-10.06 4.5-10.06 10.06a10.055 10.055 0 0016.6 7.64c.94 1.44 2.46 2.4 4.84 2.42.4 0 2.56.08 2.56-.94v-3.58c0-.3-.24-.64-.58-.64zm-13.34.16c-2.78 0-5.04-2.26-5.04-5.04 0-2.78 2.26-5.04 5.04-5.04 2.78 0 5.04 2.26 5.04 5.04-.02 2.78-2.28 5.04-5.04 5.04z"
        fill="#007DC1"
      />
    </svg>
  )
}

export default SvgOktaLogo
