import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export const OrganizationFinderDesktop = () => {
  return (
    <StaticImage
      src="https://res.cloudinary.com/cryptr/image/upload/v1655966328/Cryptr%20www/Landing%20pages/Enterprise%20ready/Optimized%20images/organisation_finder_desktop_illustration_tizq6i.jpg"
      alt="Organisation finder illustration"
      quality={50}
      width={1080}
      formats={['auto', 'webp', 'avif']}
      placeholder="none"
      className="desktop-image"
    />
  )
}

export const OrganizationFinderMobile = () => {
  return (
    <StaticImage
      src="https://res.cloudinary.com/cryptr/image/upload/v1627043958/Cryptr%20www/Landing%20pages/Enterprise%20ready/organisation_finder_mobile_illustration_tmp7fq.jpg"
      alt="Organisation finder illustration"
      quality={50}
      width={358}
      formats={['auto', 'webp', 'avif']}
      placeholder="none"
      className="mobile-image"
    />
  )
}
