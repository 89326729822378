import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export const enterpriseConnectionsImages = {
  feature_row: [
    {
      img: (
        <StaticImage
          src="https://res.cloudinary.com/cryptr/image/upload/v1656083873/Cryptr%20www/Landing%20pages/Enterprise%20ready/Optimized%20images/a_wide_range_of_SSO_providers_hojth5.jpg"
          alt="A wide range of SSO providers"
          imgStyle={{ borderRadius: '0.5rem' }}
          quality={50}
          width={270}
          formats={['auto', 'webp', 'avif']}
          placeholder="none"
          className="feature"
        />
      ),
    },
    {
      img: (
        <StaticImage
          src="https://res.cloudinary.com/cryptr/image/upload/v1656083874/Cryptr%20www/Landing%20pages/Enterprise%20ready/Optimized%20images/multi_tenants_organization_q8ef98.jpg"
          alt="Multi-tenants organization"
          imgStyle={{ borderRadius: '0.5rem' }}
          quality={50}
          width={270}
          formats={['auto', 'webp', 'avif']}
          placeholder="none"
          className="feature"
        />
      ),
    },
    {
      img: (
        <StaticImage
          src="https://res.cloudinary.com/cryptr/image/upload/v1656083874/Cryptr%20www/Landing%20pages/Enterprise%20ready/Optimized%20images/dedicated_infrastructure_gyi7mx.jpg"
          alt="Dedicated infrastructure"
          imgStyle={{ borderRadius: '0.5rem' }}
          quality={50}
          width={270}
          formats={['auto', 'webp', 'avif']}
          placeholder="none"
          className="feature"
        />
      ),
    },
    {
      img: (
        <StaticImage
          src="https://res.cloudinary.com/cryptr/image/upload/v1656083873/Cryptr%20www/Landing%20pages/Enterprise%20ready/Optimized%20images/just_in_time_user_provision_h2dytc.jpg"
          alt="Just-in-time user provisions"
          imgStyle={{ borderRadius: '0.5rem' }}
          quality={50}
          width={270}
          formats={['auto', 'webp', 'avif']}
          placeholder="none"
          className="feature"
        />
      ),
    },
    {
      img: (
        <StaticImage
          src="https://res.cloudinary.com/cryptr/image/upload/v1656083873/Cryptr%20www/Landing%20pages/Enterprise%20ready/Optimized%20images/ui_editor_xvsdgo.jpg"
          alt="UI Editor"
          imgStyle={{ borderRadius: '0.5rem' }}
          quality={35}
          width={270}
          formats={['auto', 'webp', 'avif']}
          placeholder="none"
          className="feature"
        />
      ),
    },
  ],
}
