import React from 'react'
import CallToActionSection from '../../components/call_to_action_section'
import FeatureRow from '../../components/features/feature_row'
import CenterHero from '../../components/hero/center_hero'
import LargeImage from '../../components/large_image'
import Layout from '../../components/layout'
import SectionWrapper from '../../components/sectionWrapper'
import SectionH2 from '../../components/section_h2'
import SectionH3Image from '../../components/section_h3_image'
import SvgEnterpriseConnectionsIcon from '../../components/svgs/icons/products/enterprise_ready_icon'
import SectionLogos from '../../components/section_logos'
import SvgAdfsLogo from '../../components/svgs/sso_logos/adfs'
import SvgPingIdentityLogo from '../../components/svgs/sso_logos/ping_identity'
import SvgOneLoginLogo from '../../components/svgs/sso_logos/onelogin'
import SvgOktaLogo from '../../components/svgs/sso_logos/okta'
import SvgVmWareLogo from '../../components/svgs/sso_logos/vmware'
import SvgAuth0Logo from '../../components/svgs/sso_logos/auth0'
import SvgJumpCloudLogo from '../../components/svgs/sso_logos/jump_cloud'
import SectionValuePropositions from '../../components/section_value_propositions'
import SectionAnchors from '../../components/section_anchors'
import { EnterpriseConnectionsHeroBackground } from '../../components/hero/hero_background'
import SvgHandsHelpingIcon from '../../components/svgs/icons/hands_helping_icon'
import SvgAppSettingsIcon from '../../components/svgs/icons/app_settings_icon'
import SvgProgressBarIcon from '../../components/svgs/icons/progress_bar_icon'
import { enterpriseConnectionsImages } from '../../components/images/enterprise_connections/feature'
import { QuickOnboardingForSSO } from '../../components/images/enterprise_connections/onboarding'
import {
  OrganizationFinderDesktop,
  OrganizationFinderMobile,
} from '../../components/images/enterprise_connections/organization_finder'

const featureIcons = [
  <SvgHandsHelpingIcon color="#7872FB" />,
  <SvgAppSettingsIcon color="#7872FB" />,
  <SvgProgressBarIcon color="#7872FB" />,
]

const SsoLogos = [
  <SvgAdfsLogo />,
  <SvgOneLoginLogo />,
  <SvgOktaLogo />,
  <SvgAuth0Logo />,
  <SvgPingIdentityLogo />,
  <SvgJumpCloudLogo />,
  <SvgVmWareLogo />,
]

const EnterpriseConnectionsPage = ({ pageContext: { data, localePath } }: any) => {
  const anchors_id = [
    data.body[1].section_H2_primary.subhead.replace(/\s+/g, '-').toLowerCase(),
    data.body[4].section_H2_primary.subhead.replace(/\s+/g, '-').toLowerCase(),
  ]
  return (
    <Layout
      helmetTitle={data.header_title.raw[0].text}
      helmetDescription={data.meta_description}
      helmetImage={data.meta_image.url}
      helmetUrl={`https://www.cryptr.co${localePath}/`}
    >
      <CenterHero
        background_shape={<EnterpriseConnectionsHeroBackground />}
        title={data.header_title.raw}
        description={data.header_description.raw}
        textAlignment="center"
        category={data.category}
        pageID={data.slug}
        icon={<SvgEnterpriseConnectionsIcon />}
        call_to_action_color={data.slug}
        call_to_action={data.call_to_action}
      >
        <div>
          <SectionWrapper disabledPadding="bottom">
            <SectionValuePropositions valuePropositions={data.value_propositions} />
          </SectionWrapper>
          <SectionAnchors anchors_id={anchors_id} slug={data.slug} anchors={data.body[0].anchors} />
          <SectionLogos slug={data.slug} logos={SsoLogos} />
        </div>
      </CenterHero>
      <SectionWrapper color="light-blue" disabledPadding="padding-auto">
        <SectionH2
          id={anchors_id[0]}
          slug={data.slug}
          title={data.body[1].section_H2_primary.title}
          description={data.body[1].section_H2_primary.description.raw}
          textAlignment="text-left"
          subhead={data.body[1].section_H2_primary.subhead}
          gridColumns={true}
        >
          <LargeImage
            imageType="desktop-image"
            shadow={false}
            image={<OrganizationFinderDesktop />}
          />
          <LargeImage
            imageType="mobile-image"
            shadow={false}
            image={<OrganizationFinderMobile />}
          />
          <FeatureRow
            slug={data.slug}
            features={data.body[3].items}
            images={enterpriseConnectionsImages.feature_row}
          />
        </SectionH2>
      </SectionWrapper>
      <SectionWrapper disabledPadding="padding-auto">
        <SectionH2
          id={anchors_id[1]}
          slug={data.slug}
          title={data.body[4].section_H2_primary.title}
          description={data.body[4].section_H2_primary.description.raw}
          textAlignment="text-left"
          subhead={data.body[4].section_H2_primary.subhead}
          gridColumns={true}
        >
          <SectionH3Image
            slug={data.slug}
            features={data.body[6].items}
            image={<QuickOnboardingForSSO />}
            contentPosition="to-top"
            featuresOrder="to-right"
            imageOrder="to-left"
            icons={featureIcons}
          />
        </SectionH2>
      </SectionWrapper>
      <CallToActionSection callToActionSectionData={data.body[7]} />
    </Layout>
  )
}

export default EnterpriseConnectionsPage
