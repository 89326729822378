import styled from '@emotion/styled'
import tw from 'twin.macro'

export const CenterHeroStyle = styled.div`
  ${tw`
    pt-16
    pb-8
    lg:py-32
    flex
    flex-col
    md:items-center
  `}
  &.left {
    ${tw`
      md:text-left
    `}
  }
  &.center {
    ${tw`
      md:text-center
    `}
  }
`

export const HeroDescription = styled.div`
  ${tw`
    mt-8
    text-lg
    text-purple-300
    sm:text-xl
    md:max-w-3xl
  `}
  &.home {
    strong {
      ${tw`
        text-pink-500
      `}
    }
  }
  &.authentication {
    strong {
      ${tw`
        text-blue-400
      `}
    }
  }
  &.users-management {
    strong {
      ${tw`
        text-green-500
      `}
    }
  }
  &.ui-editor {
    strong {
      color: #7126f2;
    }
  }
  &.enterprise-connections {
    strong {
      color: #5851ff;
    }
  }
`
