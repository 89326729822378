import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export const HeroIllustration = () => {
  return (
    <StaticImage
      src="https://res.cloudinary.com/cryptr/image/upload/v1655981277/Cryptr%20www/Landing%20pages/Enterprise%20ready/Optimized%20images/enterprise_ready_hero_illustration_lv138u.png"
      alt="Enterprise Connections illustration"
      quality={50}
      formats={['auto', 'webp', 'avif']}
      placeholder="none"
    />
  )
}
